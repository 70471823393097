import React, {useEffect, useState} from 'react';
import findRealItem from "../utils/meetings/findRealItem";
import {Alert} from "react-bootstrap";
import AnchoredImageSet from "./AnchoredImageSet";

function AgendaPageDisplay(props) {
    const meeting = props.meeting;
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [loadedSets, setLoadedSets] = useState(0);

    // Calculate total number of AnchoredImageSet components
    let totalNumberOfImageSets = meeting['categories'].reduce((total, cat) => {
        return total + cat['items'].reduce((itemTotal, jsonItem) => {
            return itemTotal + findRealItem(meeting, jsonItem.id)['attachment_set'].length;
        }, 0);
    }, 0);

    window.addEventListener('scroll', (event) => {
        console.log(event);
    });

    // Add one for the agenda list
    totalNumberOfImageSets += 1;

    const onImagesSetLoaded = () => {
        setLoadedSets(prevCount => prevCount + 1);
    }

    useEffect(() => {
        if (loadedSets === totalNumberOfImageSets) {
            props.handleFullyLoaded();
            setFullyLoaded(true);
        }
    }, [loadedSets, totalNumberOfImageSets]);

    const options = {
        root: null, // viewport
        rootMargin: '0px', // no margins
        threshold: [0, 0.5] // trigger when 10% of the element is visible
    };

    const attachmentObserver = new IntersectionObserver((entries) => {
        console.log('enableIntersectObserver', props.enableIntersectObserver)
        if (!props.enableIntersectObserver) return;

        // Find the first entry that is intersecting
        const firstEntry = entries.find(entry => entry.isIntersecting && entry.intersectionRatio > 0.5);

        // If there's an intersecting entry, handle the scroll event
        if (firstEntry) {
            props.handleScroll(firstEntry.target);
        }
    }, options);

    document.querySelectorAll('.anchor-tag').forEach(anchor => {
        attachmentObserver.observe(anchor);
    });

    return (
        <div id={'page_container w-100 overflow-auto'}>
            {fullyLoaded ? null : (
                <Alert id={'loading-alert'} variant={'info'}>
                    {loadedSets} of {totalNumberOfImageSets} documents are loaded. Please Wait!<br/>
                </Alert>
            )}
            <AnchoredImageSet
                id={0}
                mtgId={meeting.id}
                anchorTag={'agenda_list_pdf_anchor'}
                onAllImagesLoaded={onImagesSetLoaded}
                category={{cat: 0}}
            />
            {meeting['categories'].map(cat => {
                const hasItems = cat['items'].length > 0;

                return (
                    <div id={`category_${cat['cat']}`} key={cat.cat}>
                        {cat['items'].map(jsonItem => {

                            const realItem = findRealItem(meeting, jsonItem.id)

                            return (
                                <div id={`item_${realItem.id}`} key={realItem.id}>
                                    {realItem['attachment_set'].map(att => {
                                        return (
                                            <AnchoredImageSet
                                                key={att.id}
                                                id={att.id}
                                                mtgId={meeting.id}
                                                anchorTag={`attachment_${att.id}`}
                                                onAllImagesLoaded={onImagesSetLoaded}
                                                item={realItem}
                                                category={cat}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )

}

export default AgendaPageDisplay;