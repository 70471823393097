function getAttachmentsFromMeeting(meetingData) {
    const attachmentsList = [];

    // Iterate through the items to find the attachments
    const items = meetingData.items || [];
    items.forEach(item => {
        const attachmentSet = item.attachment_set || [];
        attachmentSet.forEach(attachment => {
            // Map the attachment to the desired structure
            attachmentsList.push({
                "id": attachment.id,
                "url": attachment.file
            });
        });
    });

    return attachmentsList;
}

export default getAttachmentsFromMeeting;
