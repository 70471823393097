import {openDB} from "idb";

async function getPageCount(id, mtgId) {
    const storeName = `page-counts-for-mtg-${mtgId}`;
    const imageStoreName = `images-for-mtg-${mtgId}`;
    const db = await openDB(`pdf-images-${mtgId}`, 1, {
        upgrade(db) {
            console.log('Upgrading database in getPageCount')
            db.createObjectStore(storeName);
            db.createObjectStore(imageStoreName);
        },
    });

    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);

    return await store.get(id);
}

export default getPageCount;