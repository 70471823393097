import {openDB} from 'idb';

async function getImageDataById(id, mtgId, pageNum) {
    const storeName = `images-for-mtg-${mtgId}`;

    const db = await openDB(`pdf-images-${mtgId}`, 1, {
        upgrade(db) {
            db.createObjectStore(storeName);
        },
    });

    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);

    return await store.get(`${id}-${pageNum}`);
}

export default getImageDataById;