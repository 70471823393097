import { getDocument } from 'pdfjs-dist';
import { openDB } from 'idb';

async function storePdfs(pdfs, mtgId, onProgressUpdate) {

    const imageStoreName = `images-for-mtg-${mtgId}`;
    const pageCountStoreName = `page-counts-for-mtg-${mtgId}`;
    const dbName = `pdf-images-${mtgId}`;

    const db = await openDB(dbName, 1, {
        upgrade(db, oldVersion) {
            db.createObjectStore(imageStoreName);
            db.createObjectStore(pageCountStoreName);
        }
    });

    const trackingDb = await openDB('complete-meetings', 1, {
        upgrade(db, oldVersion) {
            db.createObjectStore('complete-meetings');
        }
    });

    if (await trackingDb.get('complete-meetings', mtgId)) {
        return;
    }

    const resolution_scale = 3

    for (let i = 0; i < pdfs.length; i++) {
        const { id, url } = pdfs[i];

        if (onProgressUpdate) {
            onProgressUpdate((i + 1) / pdfs.length, url.split('/').pop());
        }

        if (await db.get(pageCountStoreName, id)) {
            continue;
        }

        const pdf = await getDocument(url).promise;

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: resolution_scale });

            const canvas = document.createElement('canvas');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const context = canvas.getContext('2d');

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;
            const imageData = canvas.toDataURL('image/jpeg');

            await db.put(imageStoreName, imageData, `${id}-${pageNum}`);
        }

        await db.put(pageCountStoreName, pdf.numPages, id);
    }

    await trackingDb.put('complete-meetings', true, mtgId);
}

export default storePdfs;