const clearAllIndexedDBs = async () => {
    // Open a connection to the server
    const databases = await window.indexedDB.databases();

    databases.forEach((db) => {
        console.log('attempting to delete database:', db.name);
        window.indexedDB.deleteDatabase(db.name);
    });

};

export default clearAllIndexedDBs;