import React, { useState, useEffect } from "react";
import getImageDataById from "../utils/files/getImageDataById";
import getPageCount from "../utils/files/getPageCount";


function AnchoredImageSet(props) {
    const { anchorTag, id, mtgId, onAllImagesLoaded } = props;
    const [images, setImages] = useState([]);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);

    useEffect(() => {
        fetchImageData()
        .then(imageArray => {
            setImages(imageArray)
        })
    }, []);

    useEffect(() => {
        // Check if all images are loaded
        if (loadedImagesCount === images.length && images.length > 0) {
            // Tell the parent component that all images are loaded
            if (onAllImagesLoaded) onAllImagesLoaded();
        }
    }, [loadedImagesCount, images]);

    async function fetchImageData() {
        const pageCount = await getPageCount(id, mtgId);
        const imageArray = [];
        for (let i = 1; i <= pageCount; i++) {
            const imageData = await getImageDataById(id, mtgId, i);
            imageArray.push(imageData);
        }
        return imageArray
    }

    function onImageLoad() {
        setLoadedImagesCount(prevCount => prevCount + 1);
    }

    return (
        <div
            id={anchorTag}
        >
            {images.map((image, index) => {
                return (
                    <div key={`page_${index + 1}`} className={'border border-2 mb-3'}>
                        <img
                            src={image}
                            onLoad={onImageLoad}
                            className={'w-100 anchor-tag'}
                            alt={`Page ${index + 1}`}
                            data-attachment={props.id}
                            data-item={props.item ? props.item.id : null}
                            data-category={props.category ? props.category.cat : null}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default AnchoredImageSet;
