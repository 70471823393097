import React, {useEffect, useState} from 'react';
import {Accordion, Button, Col, Container, ListGroup, Row} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import api from "./utils/api/api";
import getMeetingsPath from "./utils/api/getMeetingsPath";
import {dummyMeetingLong} from "./utils/api/dummyMeetingLong";
import Sticky from 'react-sticky-el';
import AgendaPageDisplay from "./components/AgendaPageDisplay";
import scrollToItem from "./utils/visuals/scrollToItem";

import getAttachmentsFromMeeting from "./utils/files/getAttachmentsFromMeeting";
import storePdfs from "./utils/files/storePdfs";
import {dummyMeetingShort} from "./utils/api/dummyMeetingShort";
import clearAllIndexedDBs from "./utils/db/clearAllIndexedDBs";
import {openDB} from "idb";
import { throttle } from 'lodash';


const pdfjs = await import('pdfjs-dist/build/pdf');
const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


function App() {
    const [loading, setLoading] = useState(false);
    const [loadingMeeting, setLoadingMeeting] = useState(false);
    const [loadingBar, setLoadingBar] = useState(0);
    const [currentlyLoading, setCurrentlyLoading] = useState();
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [meetings, setMeetings] = useState();
    const [error, setError] = useState();
    const [activeCategory, setActiveCategory] = useState(-1);
    const [activeItem, setActiveItem] = useState(0);
    const [activeAttachment, setActiveAttachment] = useState(0);
    const [showClearedNotification, setShowClearedNotification] = useState(false);

    // const [allowScrollEvent, setAllowScrollEvent] = useState(true);

    const [meeting, setMeeting] = useState(localStorage.getItem('meeting') ? JSON.parse(localStorage.getItem('meeting')) : null);
    // const [meeting, setMeeting] = useState(dummyMeetingLong);
    // const [meeting, setMeeting] = useState(dummyMeetingShort);

    const [observerIsActive, setObserverIsActive] = useState(false);

    const trackLoading = (val, file) => {
        setLoadingBar(Math.floor(val*100))
        setCurrentlyLoading(file)
    }

    const prepareAttachments = async (mtg) => {
        const agendaPdfItem = {
            id: 0,
            url: mtg['agenda_list_pdf'],
        }
        const attachments = getAttachmentsFromMeeting(mtg)

        attachments.unshift(agendaPdfItem)

        await storePdfs(attachments, mtg.id, (val, file) => trackLoading(val, file))
        setLoadingMeeting(false)
        setLoading(false)
        setLoadingBar(0)
    }

    useEffect(() => {
        setLoading(true);

        if (localStorage.getItem('meeting')) {
            selectMeeting(JSON.parse(localStorage.getItem('meeting')))
        } else {
            // Update loading state after fetching data
            api.get(getMeetingsPath(date))
                .then(response => {
                    setMeetings(response.data);
                    setLoading(false)
                    // prepareAttachments()
                })
                .catch(err => {
                    setError(err.message);
                    setLoading(false);
                });
        }

    }, [date]);

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const activateTag = (updateFunction, updateValue, tag) => {
        console.log('scrolling to tag: ' + tag, updateValue)
        const targetElement = document.getElementById(tag);
        targetElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        // updateFunction(updateValue)
    }

    const selectMeeting = async (mtg) => {
        setLoadingMeeting(true)
        setMeeting(mtg)
        if (!!mtg) {
            localStorage.setItem('meeting', JSON.stringify(mtg))
            await prepareAttachments(mtg)
            setLoadingMeeting(false)

        } else {
            localStorage.removeItem('meeting')
            setMeeting(null)
            setLoadingMeeting(false)
        }
    }

    const handleScrollEvent = (target) => {
        const attachmentId = parseInt(target.dataset.attachment);
        const itemId = parseInt(target.dataset.item);
        const categoryId = parseInt(target.dataset.category);

        if (activeCategory !== categoryId) setActiveCategory(categoryId)
        if (activeItem !== itemId) setActiveItem(itemId)
        if (activeAttachment !== attachmentId) setActiveAttachment(attachmentId)
    }

    if (loadingMeeting) return (
        <div className={'container'}>
            <div className={'text-center h3 mt-5'}>Loading... {loadingBar}%</div>
            <div className={'text-center h6'}>Meeting: {meeting['date']} {meeting['time']}</div>
            <div className={'text-center h5 mt-5'}>{currentlyLoading}</div>
            <div className={'text-center h6 mt-5'}>
                <Button variant={'danger'} onClick={() => selectMeeting(null)}>Cancel</Button>
            </div>
        </div>
    );

    if (loading) return (
        <div className={'container'}>
            <div className={'text-center h3 mt-5'}>Loading...</div>
        </div>

    )

    const removeAllMeetings = (e) => {
        clearAllIndexedDBs(e)
        setShowClearedNotification(true)

        // after 5 seconds, hide the notification
        setTimeout(() => {
            setShowClearedNotification(false)
        }, 5000)

    }

    if (!meeting) {
        return (
            <div className="App">
                <Container className={'text-center mt-3'}>
                    <h1>Meeting Selection</h1>
                    <h6>
                        <Button variant={showClearedNotification ? 'success' : 'primary'} type={'button'} onClick={removeAllMeetings}>
                            {showClearedNotification ? 'All Meetings Cleared' : 'Clear All Stored Meetings'}
                        </Button>
                    </h6>
                    {error && <p>{error}</p>}
                    <div className={'d-flex justify-content-center mt-2'}>
                        <Form.Control
                            value={date}
                            onChange={handleDateChange}
                            id={'meeting_date_picker'}
                            size="lg"
                            type="date"
                        />
                    </div>
                    <div className={'d-flex justify-content-center mt-4'}>
                        <ListGroup id="meeting_list_group">
                            {meetings && meetings.map(mtg => (
                                <ListGroup.Item action key={mtg.id} onClick={() => selectMeeting(mtg)}>
                                    { mtg.title } (MG-{mtg.mtid})
                                </ListGroup.Item>
                            ))}
                            {meetings && meetings.length === 0 && <p>No meetings found</p>}
                        </ListGroup>
                    </div>
                </Container>
            </div>
        );
    } else {
        let itemNumber = 1;

        return (
            <div className={'App'}>
                <Container className={'text-center ps-0'} fluid>
                    <Row className={'ps-0'}>
                        <Col lg={4}>
                            <Sticky className={'overflow-y-scroll'}>
                                <div className={'scrollDiv h-100'}>
                                    <Accordion activeKey={activeCategory} flush className={'border'}>
                                    <Accordion.Item eventKey={0} id={'agenda-btn'}>
                                        {/*<Accordion.Header onClick={() => setActiveCategory(0)} className={`no-focus-outline no-chevron`}>*/}
                                        <Accordion.Header onClick={() => activateTag(setActiveCategory, 0, `agenda_list_pdf_anchor`)} className={`no-focus-outline no-chevron`}>
                                            Agenda
                                        </Accordion.Header>
                                    </Accordion.Item>
                                {meeting['categories'].map((category, index) => {
                                    // console.log('category', category)

                                    const realItems = category['items'].map((item) => {
                                        return meeting['items'].find(i => i['agid'] === `${item['id']}`)
                                    })
                                    const indexPlusOne = index + 1

                                    const hasItems = realItems.length > 0

                                    return (
                                        <Accordion.Item eventKey={category.cat} key={category.cat} id={`cat-${category.cat}-btn`}>
                                            <Accordion.Header onClick={() => {
                                                if (hasItems) {
                                                    activateTag(setActiveCategory, category.cat, `category_${category['cat']}`)
                                                }
                                            }} className={`no-focus-outline no-chevron`}>
                                                {category['cat_name']}
                                                {!hasItems && <span className={'text-muted small'}>&nbsp;&nbsp;(no items)</span>}
                                            </Accordion.Header>
                                            {hasItems ?
                                                <Accordion.Body className={'py-0 pe-0'}>
                                                    {/* Start 2nd accordion*/}
                                                    <Accordion activeKey={activeItem} className={'m-0'}>
                                                        {realItems.map((item, idx) => (
                                                            <Accordion.Item eventKey={item.id} key={item.id}>
                                                                <Accordion.Header onClick={() => activateTag(setActiveItem, item.id ,`item_${item.id}`)} className={'no-focus-outline no-chevron'}>
                                                                    {itemNumber++}. {item['data']['title']}
                                                                </Accordion.Header>
                                                                <Accordion.Body className={'py-0'}>
                                                                    <ListGroup variant={'flush'}>
                                                                        {item['attachment_set'].map((attachment) => (
                                                                            <ListGroup.Item
                                                                                key={attachment.id}
                                                                                action
                                                                                active={activeAttachment === attachment['id'] ? 'text-start' : ''}
                                                                                onClick={() => activateTag(setActiveAttachment, attachment['id'], `attachment_${attachment['id']}`)}
                                                                            >
                                                                                {attachment['label']}
                                                                            </ListGroup.Item>
                                                                        ))}
                                                                    </ListGroup>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            ))}
                                                    </Accordion>
                                                    {/*End 2nd accordion*/}
                                                </Accordion.Body>
                                            : null}
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                                </div>
                            </Sticky>
                        </Col>
                        <Col lg={8} className={'mt-3'}>
                            <h1>Meeting Details</h1>
                            <h6>
                                <a href="#" className='link-unstyled' onClick={() => selectMeeting(null)}>
                                    { meeting.public_title }
                                </a>
                            </h6>
                            <AgendaPageDisplay
                                meeting={meeting}
                                handleScroll={handleScrollEvent}
                                handleFullyLoaded={() => setObserverIsActive(true)}
                                enableIntersectObserver={observerIsActive}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

export default App;


